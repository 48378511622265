import React, { useState, useEffect } from 'react'
import Layout from '../layouts/defaultLayout'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from '../pagesStyles/helpStyles'
import { Typography } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CHRContentContainer from '../components/contentContainer'
import CHRSectionContainer from '../components/sectionContainer'
import FaqsTab from '../components/helpPageTabs/faqs'
import HelpAndTipsTab from '../components/helpPageTabs/helpAndTips'
import HelpPageDropDown from './../components/helpPageTabs/helpPageDropdown'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

interface HelpPageProps {
  t: TFunction
  location: Location
}

const tabsNames = {
  helpAndTips: 'tips',
  faqs: 'faqs',
}

const HelpPage = ({ t, location }: HelpPageProps) => {
  const classes = styles()
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    // check hash value in page url
    location.hash.split('#')[1] === tabsNames.faqs
      ? setTabValue(1)
      : setTabValue(0)

    // scroll page up when navigating
    window.scrollTo(0, 0)
  }, [location])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Layout>
      <CHRSectionContainer>
        <section className={classes.tabsSection}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="help tabs"
            variant="fullWidth"
            // indicatorColor="transparent"
          >
            <Tab
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
              label={t('help.helpAndTipsTab.heading')}
              {...a11yProps(0)}
            />
            <Tab
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
              label={t('help.faqsTab.heading')}
              {...a11yProps(1)}
            />
          </Tabs>
        </section>
        <CHRContentContainer>
          <HelpPageDropDown switchTab={handleChange} />
          <section className={classes.tabPanelSection}>
            {/*------------ Help And Tips TAB ------------*/}
            <TabPanel value={tabValue} index={0}>
              <Typography variant="h1" component="h1" className={classes.title}>
                {t('help.helpAndTipsTab.heading')}
              </Typography>
              <HelpAndTipsTab />
            </TabPanel>
            {/*------------ FAQS TAB ------------*/}
            <TabPanel value={tabValue} index={1}>
              <Typography variant="h1" component="h1" className={classes.title}>
                {t('help.faqsTab.heading')}
              </Typography>
              <FaqsTab />
            </TabPanel>
          </section>
        </CHRContentContainer>
      </CHRSectionContainer>
    </Layout>
  )
}
export default withTranslation()(HelpPage)
