import { makeStyles } from '@material-ui/core/styles'
import { WIDTH_DIMENSIONS, COLORS, FONTS, BREAKPOINTS } from '../constants'

export const styles = makeStyles(theme => ({
  root: {
    opacity: 1,
    overflow: 'initial',
    fontFamily: FONTS.Graphik.GraphikRegular,
    textTransform: 'lowercase',
    color: COLORS.lightGrey,
    backgroundColor: COLORS.whiteStain,
    border: 'none',
    fontSize: '0.75rem',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '0.857rem',
    },
  },
  selected: ({
    selectedBgColor = theme.palette.common.black,
  }: {
    selectedBgColor?: string
  }) => ({
    backgroundColor: `${selectedBgColor} !important`,
    color: `${theme.palette.common.white} !important`,
    border: 'none !important',
    '& + $root': {
      zIndex: 1,
    },
    '& + $root:before': {
      opacity: 0,
    },
  }),
  tabsSection: {
    width: '100%',
    margin: 'auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.xmd}px)`]: {
      width: '85%',
      display: 'block',
    },
  },
  title: {
    marginTop: 50,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      marginTop: 80,
    },
  },
  tabPanelSection: {
    maxWidth: 700,
    margin: '0px auto',
  },
}))
