import React from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Typography } from '@material-ui/core'
import CHRAccordion from '../accordion'
import activeIcon from '../../assets/images/Chirpyest_Member_page_icon@2x.png'
import inactiveIcon from '../../assets/images/chirpyest-disabled-32x32.png'
import hammerIcon from '../../assets/images/Google puzzle@2x.png'
import pinIcon from '../../assets/images/Google pin@2x.png'
import { styles } from './helpPageDropdown/styles'

interface helpAndTipsTabProps {
  t: TFunction
}

const helpAndTipsTab = ({ t }: helpAndTipsTabProps) => {
  const classes = styles()

  return (
    <>
      {/*------------------- pin browser extension -------------------*/}
      <CHRAccordion title={t('help.helpAndTipsTab.pinBrowserExtension.title')}>
        <Typography variant="subtitle1" component="p">
          {t('help.helpAndTipsTab.pinBrowserExtension.subtitle')}{' '}
          <img
            src={hammerIcon}
            alt={t('imageAlts.hammer')}
            className={classes.icon}
          />{' '}
          {t('help.helpAndTipsTab.pinBrowserExtension.subtitle1')}{' '}
          <img
            src={pinIcon}
            alt={t('imageAlts.pin')}
            className={classes.icon}
          />{' '}
          {t('help.helpAndTipsTab.pinBrowserExtension.subtitle2')}{' '}
          <img
            src={activeIcon}
            alt={t('imageAlts.active')}
            className={classes.extension}
          />
        </Typography>
      </CHRAccordion>
      {/*----------------- activate cash back -----------------*/}
      <CHRAccordion title={t('help.helpAndTipsTab.activateCashBack.title')}>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.activateCashBack.subtitle')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.activateCashBack.article1.title')}{' '}
            <img
              src={activeIcon}
              alt={t('imageAlts.active')}
              className={classes.extension}
            />
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.activateCashBack.article2.title')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.activateCashBack.article3.title')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.activateCashBack.article4.title')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.activateCashBack.article5.title')}
          </Typography>
        </article>
      </CHRAccordion>
      {/*----------------- shopping on mobile -----------------*/}
      <CHRAccordion title={t('help.helpAndTipsTab.shoppingOnMobile.title')}>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.shoppingOnMobile.subtitle')}
          </Typography>
        </article>
      </CHRAccordion>
      {/*----------------- always earn cash back -----------------*/}
      <CHRAccordion title={t('help.helpAndTipsTab.alwaysEarn.title')}>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.alwaysEarn.subtitle')}
          </Typography>
        </article>
      </CHRAccordion>
      {/*----------------- setting up -----------------*/}
      <CHRAccordion title={t('help.helpAndTipsTab.settingUp.title')}>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.subtitle')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.article1.title')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.article1.subtitle')}{' '}
            <img
              src={inactiveIcon}
              alt={t('imageAlts.inactive')}
              className={classes.extension}
            />
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.article1.subtitle1')}{' '}
            <img
              src={activeIcon}
              alt={t('imageAlts.active')}
              className={classes.extension}
            />
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.article2.title')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.article3.title')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.article4.title')}
          </Typography>
        </article>
        <article>
          <Typography variant="subtitle1" component="p">
            {t('help.helpAndTipsTab.settingUp.article5.title')}
          </Typography>
        </article>
      </CHRAccordion>
    </>
  )
}
export default withTranslation()(helpAndTipsTab)
